export default {
  user: {
    role_name: "角色名称",
    description: "描述",
    create_role: "创建角色",
    assign_role_permissions: "分配角色权限",
    address_name: "地址名称",
    create_address: "创建地址",
    sms_notification: "短信通知",
    email_notification: "邮件通知",
    sms_notification_tips: "短信将会发送到主账号手机号码",
    email_notification_tips: "若未配置通知邮箱，将会发送通知到主账号安全邮箱",
    mailbox_not_configured: "邮箱未配置",
    configure_email: "配置邮箱",
    send_email_verification_code: "发送邮件验证码",
    send_sms_verification_code: "发送短信验证码",
    email_sent_successfully: "邮件发送成功",
    sms_sent_successfully: "短信发送成功",
    notification_mailbox_modified_successfully: "通知邮箱修改成功",
    secure_mailbox_modified_successfully: "安全邮箱修改成功",
  },
};
